<template>
    <Cart />
</template>

<script>
import Cart from '@apps/Orders/components/Cart/Cart.vue'
export default {
    name: 'HeaderCart',
    components: {
        Cart
    }
}
</script>